import React, { useContext, useState, useEffect } from 'react';

import Styled from 'styled-components';
import Page from '../components/Page';
import Centered from '../components/Centered';
import Request from 'request-promise';
import Config from '../Config';
import { AppContext } from '../App';

import { Icon, Label } from 'semantic-ui-react';

import Host from '../interfaces/Host';

function statusColour(status: string) {
    switch (status.toLowerCase()) {
        case 'online':
            return "rgb(46,204,113)";
        case 'offline':
            return "rgb(231,76,60)";
        case 'maintenance':
            return "rgb(155,89,182)";
        case 'busy':
            return "rgb(230,126,34)";
        default:
            return "rgb(189,195,199)";
    }
}

const HostTile = Styled.div<{ status: string }>`
    display:inline-block;
    margin:10px;
    background:${p => statusColour(p.status)};
    border:solid 1px ${p => statusColour(p.status)};
    border-radius:5px;

    width:250px;
    height:141px;

    position:relative;
    overflow:hidden;

    /* box-shadow:5px 5px 15px rgba(0, 0, 0, .5); */

    img {
        position:absolute;
        left:50%;
        top:50%;
        height:100%;
        transform:translate(-50%, -50%);
        z-index:1;
        opacity:.9;
        transition:opacity linear .1s;
    }
    * {
        z-index:2;
    }

    &:hover {
        cursor:pointer;
    }

    ${ p => {
        if (p.status.toLowerCase() === "offline") return;
        return `
            &:hover {
                transform:scale(1.05);
                transition:transform linear .05s;
                img {
                    opacity: 1;
                    transition:opacity linear .1s;
                }
            }
            transition:transform linear .05s;
        `;
    }};
`;
const FlexContainer = Styled.div`
    display:flex;
    flex-direction:column;

    width:100%;
    height:100%;
`;
const Spacer = Styled.div`
    flex:1;
`;
const HostTileName = Styled.div`
    padding:10px;
    font-size:16pt;
    background:rgba(0, 0, 0, .5);
`;
const HostTileComment = Styled.div`
    font-size:10pt;
    padding-top:10px;
    display:block;
    text-align:center;
`;
const HostTileStatus = Styled.div`
    padding:5px;
    text-shadow:0 0 5px black;
    background:rgba(0, 0, 0, .5);
`;

const StatusBullet = Styled.div<{ status: string }>`
    background-color:${ p => statusColour(p.status)};
    border-radius:50%;
    width:10px;
    height:10px;
    display:inline-block;
    margin-right:5px;
    margin-left:5px;
    border:solid 1px white;
`;

const PageTitle = Styled.div`
    background: rgba(0, 0, 0, .7);
    padding:15px;
    font-size:20px;
`;

const HostTiles = Styled.div`
    padding:25px;
`;
const HostGroup = Styled.div`
    margin-bottom:20px;
    overflow:hidden;
    border-radius:5px;
`;
const HostGroupTitle = Styled.div`
    padding:10px;
    background:rgba(0, 0, 0, .4);
`;
const HostGroupContent = Styled.div`
    background:rgba(0, 0, 0, .7);
`;

interface HostGroup {
    name: string,
    hosts: string[],
}

export default ()=>{
    const ctx = useContext(AppContext);
    const [ hosts, setHosts ] = useState([] as Host[]);
    const [ hostGroups, setHostGroups ] = useState([] as HostGroup[]);

    const [ lastCheck, setLastCheck ] = useState(-1);

    useEffect(()=>{
        ctx.setPageTitle("Home");

        ctx.setSidebarEnabled(true);
        fetchHosts();
        fetchGroups();
        setInterval(()=>{
            fetchHosts();
        }, 5 * 1000);
    }, []);

    const fetchHosts = async () => {
        try {
            const hs = await Request({
                method: "GET",
                uri: `${ctx.getAPIEndpoint()}/api/v1/hosts`,
                headers: {
                    Authorization: `JWT ${ctx.authToken}`,
                },
                json: true,
            });
            setLastCheck( Date.now() );
            setHosts(hs as Host[]);
            if (ctx.loadingState) ctx.setLoadingState(false);
        } catch (e) {
            ctx.setCurrentError(`Error fetching Hosts, ${e.toString()}`);
            console.log(e);
        }
    }

    const fetchGroups = async () => {
        try {
            const hs = await Request({
                method: "GET",
                uri: `${ctx.getAPIEndpoint()}/api/v1/groups`,
                headers: {
                    Authorization: `JWT ${ctx.authToken}`,
                },
                json: true,
            });
            setHostGroups(hs as HostGroup[]);
            if (ctx.loadingState) ctx.setLoadingState(false);
        } catch (e) {
            ctx.setCurrentError("Error fetching groups!");
            console.log(e);
        }
    }

    const readStatus = (host: Host) => {
        if (host.maintenance) return "Maintenance";
        if (!host.online) return "Offline";
        if (host.inUse) return "Busy";
        return "Online";
    }

    const readStatusNice = (host: Host) => {
        if (host.maintenance) return "MAINTENANCE";
        if (!host.online) return "OFFLINE";
        if (host.inUse) {

            if (ctx.authState?.usingOAuth && host.user) {
                return host.user;
            } else {
                return "IN USE";
            }
        }
        return "AVAILABLE";
    }

    const connect = (host:Host) => {
        if (host.maintenance) {
            ctx.setActiveModal({
                icon: 'wrench',
                title: "Maintenance Mode",
                message: "That desktop is currently under maintenance, please contact IT if you require access to data situated on it.",
                onClose: ()=>{
                    console.log("Home saw close");
                },
                buttons: [
                    {
                        text: "Okay",
                        color: 'green',
                        icon: 'check',
                    }
                ],
            });
            return;
        }
        if (host.inUse) {
            ctx.setActiveModal({
                icon: 'warning',
                title: "Desktop is in use",
                message: "Someone is using that Desktop, please try again later.",
                onClose: ()=>{
                    console.log("Home saw close");
                },
                buttons: [
                    {
                        text: "Okay",
                        color: 'green',
                        icon: 'check',
                    }
                ],
            });
            return;
        }
        if (!host.online) {
            ctx.setActiveModal({
                icon: 'warning',
                title: "Desktop is offline!",
                message: "That desktop is currently offline, please contact the IT Team",
                onClose: ()=>{
                    console.log("Home saw close");
                },
                buttons: [
                    {
                        text: "Okay",
                        color: 'green',
                        icon: 'check',
                    }
                ],
            });
            return;
        }
        window.location.href = `/connect/${host.id.toLowerCase()}`;
    }

    const groupedHosts = () => {
        let groups = [] as { name: string, hosts: Host[] }[];

        // All hosts in a group
        let grouped = [] as string[];

        for (let g of hostGroups) {
            let obj = {
                name: g.name,
                hosts: [] as Host[],
            };

            for (let h of hosts) {
                if (g.hosts.indexOf(h.id.toLowerCase()) >= 0) {
                    obj.hosts.push(h);
                    if (grouped.indexOf(h.id) < 0) {
                        grouped.push(h.id);
                    }
                }
            }

            groups.push(obj);
        }
        const unsortedGroup = {
            name: "Unsorted",
            hosts: [] as Host[],
        };

        for (let h of hosts) {
            if (grouped.indexOf(h.id) < 0) {
                unsortedGroup.hosts.push(h);
            }
        }
        groups.push(unsortedGroup);

        return groups;
    }


    return (
        <Page background={"linear-gradient(#008996, #002d31)"} padding={0}>
            <PageTitle>Desktops ({hosts.length})</PageTitle>
            <HostTiles>
                {groupedHosts().map( (group, index ) => {
                    if (group.hosts.length <= 0) return;
                    return (
                        <HostGroup key={index}>
                            <HostGroupTitle>{group.name}</HostGroupTitle>
                            <HostGroupContent>
                                {group.hosts.map( (host, index) => {
                                    return (
                                        <HostTile key={index} status={readStatus(host)} onClick={()=>{ connect(host); }}>
                                            <FlexContainer>
                                                <img src={`${ctx.getAPIEndpoint()}/api/v1/thumbnail/${host.id}?token=${ctx.authToken}&t=${lastCheck}`}/>
                                                <HostTileName>{host.special && <Icon name="star" size="small" color="yellow" />} {host.displayName} {host.comment && <HostTileComment>{host.comment}</HostTileComment>}</HostTileName>
                                                <Spacer></Spacer>
                                                <HostTileStatus><StatusBullet status={readStatus(host)} /> {readStatusNice(host)}</HostTileStatus>
                                            </FlexContainer>
                                        </HostTile>
                                    );
                                })}
                            </HostGroupContent>   
                        </HostGroup>
                    );
                })}
                {hosts.length <= 0 && <Centered>
                    <h1>No hosts</h1>
                    <h3>
                        There's no hosts to show!
                        <br/>
                        Contact the IT Team for more information.
                    </h3>
                </Centered>}
            </HostTiles>
        </Page>
    );
}