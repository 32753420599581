import Styled from 'styled-components';

export default Styled.div<{
    background?: string | {
        image?: string,
        size?: string,
        position?: string,
    },
    blocking?: boolean,
    padding?: number,
    flexbox?: boolean,
    flexDirection?: string,
    modal?: boolean,
}>`
    ${ p => {
        if (!p.background || typeof p.background === "string") {
            return `background:${( p.background ? p.background : "#111")};`;
        } else {
            let css = ``;

            if (p.background.image) {
                css += `background-image:${p.background.image};`;
            }
            if (p.background.size) {
                css += `background-size:${p.background.size};`;
            }
            if (p.background.position) {
                css += `background-position:${p.background.position};`;
            }

            return css;
        }
    }}
    box-sizing:border-box;
    color:white;
    overflow-y:auto;
    position:relative;

    ${ p => {

        if (p.blocking) {
            return `
            width:100vw;
            height:100vh;
            `;
        } else {
            return `
                flex:1;
            `;
        }
    }}

    ${ p => {

        const padding = (typeof p.padding === "undefined" ? 25 : p.padding);
        return `padding:${padding}px;`;

    }}

    ${ p => {
        return `display:${(p.flexbox === true ? "flex" : "block")};`;
    }}

    ${ p => {
        if (p.flexbox) {
            return `flex-direction:${(p.flexDirection || "row")}`;
        }
    }};

    ${ p => p.modal && `z-index:-1000000000;`}
`;