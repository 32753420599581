import React from 'react';

import Page from '../components/Page';
import Centered from '../components/Centered';

import './Loading.scss';

export default () => {

    return (
        <Page blocking modal background={"rgba(0, 0, 0, .8)"}>
            <Centered>
                <div className="sk-folding-cube">
                    <div className="sk-cube1 sk-cube"></div>
                    <div className="sk-cube2 sk-cube"></div>
                    <div className="sk-cube4 sk-cube"></div>
                    <div className="sk-cube3 sk-cube"></div>
                </div>
            </Centered>
        </Page>
    );
}