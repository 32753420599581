export default {
    apiEndpoint: "https://remote.bridgeway.consulting",
    webSocketEndpoint: "wss://remote.bridgeway.consulting/ws",
    development: {
        enabled: false,
        apiEndpoint: "http://localhost:8081",
        webSocketEndpoint: "ws://localhost:8081/ws",
    },
    beta: true,
    systemTitle: "gINT Remote Access",
    oauthEnabled: true,
}