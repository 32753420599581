import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../App';
import Styled from 'styled-components';
import queryString from 'query-string';
import Config from '../Config';

import Page from '../components/Page';
import Centered from '../components/Centered';

import Logo from '../bridgeway_logo.png'
import BridgewayHouse from '../assets/bridgeway_house.png';

import Request from 'request-promise';

const Gradient = Styled.div`
    width:100vw;
    height:100vh;

    background:linear-gradient( rgba(0, 0, 0, .25), rgba(0, 0, 0, .8));
`;
const LoginForm = Styled.div`
    background:rgba(0, 0, 0, .5);
    padding:20px;
    border-radius:5px;
    h1 {
        margin-bottom:20px;
    }
`;

export default () => {
    const ctx = useContext(AppContext);

    const [ oauthCode, setOAuthCode ] = useState("");
    const [ status, setStatus ] = useState("Checking OAuth");

    useEffect(()=>{
        ctx.setSidebarEnabled(false);
        ctx.setLoadingState(false);

        const parsed = queryString.parse(window.location.search.substr(1));
        console.log(parsed);
        if (parsed.code) {
            setOAuthCode(parsed.code as string);
        } else {
            ctx.setCurrentError("Missing Code!");
        }
    }, []);

    useEffect(()=>{
        if (oauthCode != "") {
            // Attempt to talk to the backend.
            Request({
                uri: `${ctx.getAPIEndpoint()}/api/v1/oauth/code`,
                method: "POST",
                json: true,
                body: {
                    code: oauthCode
                }
            })
            .then( d => {
                if (d.token) {
                    // Success
                    ctx.consumeToken(d.token)
                    .then( r => {
                        if (r) window.location.href = "/";
                    });
                } else {
                    // Error!
                    setStatus(d.error_description)
                }
            })
            .catch( e => {
                console.log("Error", e);
            });
        }
    }, [ oauthCode ]);

    return (
        <Page background={{
            image: `url(${BridgewayHouse})`,
            size: "cover",
            position: "center",
        }} padding={0}>
            <Gradient>
                <Centered vertically>
                    <img src={Logo} width={300} style={{ marginBottom: "30px" }}/>
                    <LoginForm>
						{status}
                    </LoginForm>
                </Centered>
            </Gradient>
        </Page>
    );
}