import React, { useState, useContext, useEffect } from 'react';

import Page from '../components/Page';
import Centered from '../components/Centered';

import { Input, Button, Form, Message, Label } from 'semantic-ui-react';
import { AppContext } from '../App';

import Config from '../Config';

import Styled from 'styled-components';
import Logo from '../assets/new_logo.png'
import BridgewayHouse from '../assets/bridgeway_house.png';


const Gradient = Styled.div`
    width:100%;
    height:100%;
    background:linear-gradient( rgba(0, 0, 0, .25), rgba(0, 0, 0, .8));
    position:relative;
`;
const LoginForm = Styled.div<{ beta: boolean }>`
    background:rgba(0, 0, 0, .5);
    padding:20px;
    border-radius:5px;
    h1 {
        margin-bottom:20px;
    }
`;
const BetaMessage = Styled.div`
	margin-top:10px;
	@keyframes float {
		0% {
			transform:translateY(0);
		}
		50% {
			transform:translateY(20px);
		}
		100% {
			transform:translateY(0);
		}
	}

	animation:float ease-in-out 6s infinite;
`;
const BackgroundImage = Styled.div`
    flex:1;
    background:url(${BridgewayHouse});
    background-size:cover;
    background-position:center;
`;
const LoginPanel = Styled.div`
    width:40vw;
`;
export default () => {
    const ctx = useContext(AppContext);

    const [ enteredPassword, setEnteredPassword ] = useState("");

    useEffect(()=>{
        ctx.setPageTitle("Login");
        ctx.setLoadingState(false);
        ctx.setSidebarEnabled(false);
    }, []);

    const attemptAuthentication = ()=>{
        if (enteredPassword === "") {
            ctx.setCurrentError("The password cannot be left blank!");
            return;
        }
        ctx.setLoadingState(true);
        ctx.authenticate(enteredPassword)
        .then( r => {
            if (r) {
                window.location.href = "/";
                return;
            } else {
                ctx.setLoadingState(false);
                ctx.setCurrentError("Invalid Credentials!");
                setEnteredPassword("");
            }
        });
	}
	
	const beginOauth = () => {
		window.location.href = `${ctx.getAPIEndpoint()}/api/v1/oauth/begin?development=${Config.development.enabled}`;
	}

    return (
        <Page background={"#008996"} flexbox padding={0}>
            <LoginPanel>
                <Gradient>
                    <Centered vertically>
                        <img src={Logo} width={300} style={{ marginBottom: "30px" }}/>
                        {!Config.oauthEnabled &&
                        <LoginForm beta={Config.beta}>
                            <Form>
                                <Input fluid placeholder="Password" type="password" value={enteredPassword} onChange={(e)=>{ setEnteredPassword(e.target.value); }}/>
                                <p />
                                <Button fluid primary onClick={()=>{ attemptAuthentication(); }} >Login</Button>
                            </Form>
                        </LoginForm>}
                        {Config.oauthEnabled &&
                        <LoginForm beta={Config.beta}>
                            <Button fluid primary onClick={()=>{ beginOauth(); }} >Login with Bridgeway</Button>
                        </LoginForm>}
                        {Config.beta &&
                            <BetaMessage>
                                <Message color="orange" icon="bug" header="Running βeta" content="This is a beta version of the Remote Access system!"/>
                            </BetaMessage>}
                    </Centered>
                </Gradient>
            </LoginPanel>
            <BackgroundImage/>
        </Page>
    );
}