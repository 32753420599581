import React, { useContext } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { AppContext } from '../App';

interface AuthRouteProps extends RouteProps {

}

export default ( props: AuthRouteProps) => {
    const ctx = useContext(AppContext);

    if (ctx.isAuthenticated && ctx.authToken && ctx.authState) {
        return <Route {...props} />;
    } else {
        return <Redirect to="/login" />;
    }
}