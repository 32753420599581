import React, { useContext, useState, useEffect, createRef, useCallback, useRef } from 'react';

import { AppContext } from '../App';
import Request from 'request-promise';
import Config from '../Config';
import Host from '../interfaces/Host';
import RFB from 'novnc-core';

import Page from '../components/Page';
import Centered from '../components/Centered';
import Styled from 'styled-components';
import useInterval from '../interfaces/useInterval';
import SidebarItem from '../interfaces/SidebarItem';

import { Button, Label, Icon, Popup } from 'semantic-ui-react';
import { EWOULDBLOCK } from 'constants';

const Screen = Styled.div<{ fullscreen: boolean }>`
    flex:1;
    ${ p => {
        if (p.fullscreen) {
            return `
                padding:0px;
            `;
        } else {
            return `padding:25px;`;
        }
    }}
    canvas {
        box-shadow: 5px 5px 15px rgba(0, 0, 0, .5);
        border:solid 1px #111;
    }
`;
const TopBar = Styled.div<{ fullscreen:boolean, pinned?: boolean }>`
    background:rgba(0, 0, 0, .7);
    padding:12px;
    display:flex;

    ${ p => {
        if (p.fullscreen && !p.pinned) {
            return `
                margin-top:-45px;
                &:hover {
                    transition: margin-top linear .2s, baackground-color linear .2s;
                    margin-top:-2px;
                    background-color:rgba(0, 0, 0, .6);
                }
            `;
        }
    }}
    ${ p => {
        if (p.fullscreen) {
            return `
                z-index:50;
                width:80vw;
                background-color:rgba(0, 0, 0, .3);
                position:fixed;
                left:50%;
                transform:translateX(-50%);
                border-radius-bottom-left:5px;
                border-radius-bottom-right:5px;
                transition: margin-top linear .2s;
                margin-bottom:10px;
            `;
        }
    }}
`;
const TopBarSection = Styled.div<{ align?: string, flex?: boolean }>`
    ${ p => {
        if (p.align) {
            return `text-align:${p.align};`;
        }
    }}

    ${ p => {
        if (p.flex) {
            return `flex:1;`;
        }
    }}
`;

const TopBarPush = Styled.div`
    flex:1;
`;
const PageContainer = Styled.div`
    display:flex;
    flex:1;
`;
const SoftKeyboardTarget = Styled.textarea`
    opacity:0;
    position:fixed;
    left:-1000px;
    top:-1000px;
`;

export default (props: any)=>{
    const ctx = useContext(AppContext);

    const vncScreen = createRef<HTMLDivElement>();
    const mainPage = createRef<HTMLDivElement>();
    const keyboardTarget = createRef<HTMLTextAreaElement>();

    const [ vncConnection, setVNCConnection ] = useState(null as null | RFB);
    const [ host, setHost ] = useState( null as Host | null);
    
    const [ connectionState, setConnectionState ] = useState("Idle");

    // Custom stuff
    const [ desktopName, setDesktopName ] = useState("");
    const [ vncConnected, setVNCConnected ] = useState(false);

    const [ fullscreen, setFullscreen ] = useState(false);
    const [ topBarPinned, setTopBarPinned ] = useState(false);

    const connectButtonCallback = useRef(()=>{
        connectSession();
    });

    useInterval(()=> {
        console.log("Automating thumbnail.");
        pushThumbnail();
    }, 10 * 1000);

    useEffect(()=>{
        ctx.setSidebarEnabled(true);

        updateSidebar();

        fetchSettings(props.match.params.host).then( settings => {
            
            setHost(settings);
            ctx.setLoadingState(false);
            if (settings && settings.maintenance) {
                ctx.setActiveModal({
                    icon: 'wrench',
                    title: "Maintenance Mode",
                    message: "That desktop is currently under maintenance!",
                    onClose: ()=>{
                        console.log("Home saw close");
                    },
                    buttons: [
                        {
                            text: "Okay",
                            color: 'green',
                            icon: 'check',
                        }
                    ],
                });
                return;
            } else if (settings && settings.inUse) {
                ctx.setActiveModal({
                    icon: 'warning circle',
                    title: "Desktop is in use",
                    message: "Someone is using that Desktop, please try again later.",
                    onClose: ()=>{
                        console.log("Home saw close");
                    },
                    buttons: [
                        {
                            text: "Okay",
                            color: 'green',
                            icon: 'check',
                        }
                    ],
                });
                return;
            } else if (settings && !settings.online) {
                ctx.setActiveModal({
                    icon: 'warning',
                    title: "Desktop is offline!",
                    message: "That desktop is currently offline",
                    onClose: ()=>{
                        console.log("Home saw close");
                    },
                    buttons: [
                        {
                            text: "Okay",
                            color: 'green',
                            icon: 'check',
                        }
                    ],
                });
                return;
            }
        });

    }, []);

    useEffect(()=>{
        // Reconnects on disconnect.
        if (!vncConnected) {
            connectSession();
        }
    }, [ host ]);

    const connectSession = () => {
        console.log("Attempting to create a connection");
        if (!vncScreen.current) {
            console.log("Missing Screen", vncScreen);
            return;
        }
        if (host === null) {
            console.log("Missing Host", host);
            return;
        }

        // Some more sanity checks
        if (host.maintenance) return;
        if (host.inUse) return;
        if (!host.online) return;

        if (vncConnection) console.log("VNC Connection already present", vncConnection);

        if (vncScreen.current && host && !vncConnection) {
            // Ready
            console.log("Starting connection");
            setConnectionState("Connecting");

            const conn = new RFB(vncScreen.current, `${ctx.getWSEndpoint()}/${host.id}?token=${ctx.authToken}`, {
                credentials: {
                    password: host.password
                }
            });

            // Set some properties
            conn.scaleViewport = true;
            conn.background = "transparent";

            conn.addEventListener("connect", (a)=>{
                console.log("Connected successfully.", );
                setConnectionState("Connected");
                setVNCConnected(true);

                // Jank-a-thon
                let c = conn as any;
                const target = ( c._target as HTMLElement );
                if (target !== null) {
                    const children = target.getElementsByTagName("canvas");
                    if (children.length > 0) {
                        children[0].contentEditable = "true";
                        children[0].addEventListener("keydown", e => {
                            //console.log(e);
                            if (e.ctrlKey && e.keyCode === 86) {
                                console.log("Triggering clipboard paste event.");
                                navigator.clipboard.readText()
                                .then( text => {
                                    console.log("Pushing Clipboard");
                                    conn.clipboardPasteFrom(text);
                                })
                                .catch( e => {
                                ctx.setCurrentError("Unable to sync clipboard to client, " + e.toString());
                                })
                            }
                        });
                    }
                }
            });
            conn.addEventListener("disconnect", (e) => {
                setConnectionState("Disconnected");
                console.log("Disconnected from session", e);
                setVNCConnected(false);
                setVNCConnection(null);
            });
            conn.addEventListener("credentialsrequired", ()=>{
                console.log("needs deets");
            });
            conn.addEventListener("clipboard", e => {
                // Hacky as all hell, create an input element on the fly, append to the document, set its value and copy the contents
                // then remove it.
                console.log(e);

                var copyText = document.createElement("input");

                document.body.appendChild(copyText);

                copyText.value = e.detail.text;
                
                // Hide our dodgy method from the user.
                copyText.style.cssText = "position:fixed; left:-10000px; top:-10000px; opacity:0;";

                copyText.select();
                copyText.setSelectionRange(0, e.detail.text.length); /*For mobile devices*/

                /* Copy the text inside the text field */
                document.execCommand("copy");
                document.body.removeChild(copyText);
            });
            conn.addEventListener("desktopname", (e) => {
                console.log(`Got Desktop Name ${e.detail.name}`);
                setDesktopName(e.detail.name);
            });
            conn.addEventListener("capabilities", e => {
                console.log("Capabilities", e);
            });
            setVNCConnection(conn);

        }
    }

    useEffect(()=>{
        if (vncConnection && vncConnected) {
            if (desktopName != "") {
                setConnectionState(`Connected to ${desktopName}`);
            } else {
                setConnectionState("Connected");
            }
        }
    }, [desktopName]);

    useEffect(()=>{
        ctx.setPageTitle(connectionState);
    }, [ connectionState ]);

    useEffect(()=>{
        updateSidebar();
    }, [ vncConnected, vncConnection, host ]);
    
    const updateSidebar = () => {
        
        ctx.setSidebarItems([
            /*{
                label: "Open Keyboard",
                icon: "keyboard",
                disabled: !vncConnected,
                onClick: ()=>{
                    triggerSoftKeyboard();
                }
            },*/
            {
                label: "Disconnect",
                icon: "unlink",
                visible: vncConnected,
                onClick: ()=>{
                    if (vncConnection) {
                        vncConnection.disconnect();
                    }
                }
            },
            /*{
                label: "Connect",
                icon: "linkify",
                visible: !vncConnected,
                onClick: () => {
                    connectButtonCallback.current();
                }
            }*/
        ]);
    }

    const goFullScreen = ()=>{
        if (vncScreen.current && vncConnected && mainPage.current) {
            if (!document.fullscreenElement) {
                mainPage.current.requestFullscreen()
                setFullscreen(true);
            } else {
                document.exitFullscreen();
                setFullscreen(false);
            }
        }
    }

    const fetchSettings = async (host: string) => {
        const hosts = await Request({
            method: "GET",
            uri: `${ctx.getAPIEndpoint()}/api/v1/hosts`,
            headers: {
                Authorization: `JWT ${ctx.authToken}`
            },
            json: true,
        });
        for (let h of hosts as Host[]) {
            if (h.id.toLowerCase() === host.toLowerCase()) {
                return h;
            }
        }
        return null;
    }

    const grabScreenshot = ()=>{
        if (!vncScreen.current) return;

        let canvas = vncScreen.current.children[0].children[0] as HTMLCanvasElement;
        let dataURL = canvas.toDataURL();

        downloadFile("screenshot.png", dataURL);
    }

    const pushThumbnail = useCallback(() => {
        console.log("Attempting to push a thumbnail");

        if (!vncScreen || !vncScreen.current) {
            console.log("VNC Screen Missing");
            return;
        }

        if (!host) {
            console.log("Host Missing");
            return;
        }

        if (vncScreen.current.children.length <= 0) {
            console.log("VNC Screen Canvas Missing");
            return;
        }

        let canvas: HTMLCanvasElement | null = null;

        for (let i = 0; i < vncScreen.current.children.length; i++) {
            const elem = vncScreen.current.children.item(i);
            if (!elem) continue;
            for (let x = 0; x < elem.children.length; x++) {
                const elemTwo = elem.children.item(x);
                if (elemTwo && elemTwo.nodeName === "CANVAS") {
                    canvas = elemTwo as HTMLCanvasElement;
                }
            }
        }
        if (canvas == null) {
            console.log("Failed to find Canvas within Screen");
            return;
        }

        const thumbnailCanvas = document.createElement("canvas");
        const thumbCtx = thumbnailCanvas.getContext("2d");

        if (!thumbCtx) {
            console.log("Failed to created Thumbnail Canvas Context");
            return;
        }

        thumbnailCanvas.width = 250;
        thumbnailCanvas.height = 141;

        thumbCtx.fillStyle = "#111111";
        thumbCtx.rect(0, 0, 250, 141);
        thumbCtx.fill();

        // Scale and center the screenshot.

        let scale = 1;
        if (canvas.width < canvas.height) {
            scale = canvas.width / 250;
        } else {
            scale = canvas.height / 141;
        }

        thumbCtx.drawImage(
            canvas,
            (thumbnailCanvas.width)/2-(canvas.width / scale)/2,
            (thumbnailCanvas.height)/2-(canvas.height / scale)/2,
            canvas.width / scale,
            canvas.height / scale
        );

        thumbnailCanvas.toBlob( blobb => {
            if (!blobb) return;
            let formData = new FormData();
            formData.append("thumb", blobb, "thumb.png");
            fetch(`${ctx.getAPIEndpoint()}/api/v1/thumbnail/${host.id}`,{
                method: "PUT",
                headers: {
                    Authorization: `JWT ${ctx.authToken}`,
                },
                body: formData
            });
        })
    }, [ vncScreen, host ]);

    const downloadFile = (filename: string, data: string) => {
        const element = document.createElement('a');
        element.setAttribute('href', data);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    const readStatus = (): "green" | "red" | "orange" | "yellow" | "olive" | "teal" | "blue" | "violet" | "purple" | "pink" | "brown" | "grey" | "black" | undefined => {
        switch (connectionState.toLowerCase()) {
            case 'connected':
                return "green";
            case 'disconnected':
                return 'red';
            default:
                return "orange";
        }
    }

    const pushClipboard = () => {
        if (vncConnection) {
            navigator.clipboard.readText()
            .then( text => {
                console.log("Pushing Clipboard");
                vncConnection.clipboardPasteFrom(text);
            })
            .catch( e => {
                ctx.setCurrentError("Unable to sync clipboard to client, " + e.toString());
            })
        }
    }

    const sendCtrlAltDel = () => {
        if (vncConnection) {
            vncConnection.sendCtrlAltDel();
        }
    }

    const triggerSoftKeyboard = () => {
        if (keyboardTarget.current) {
            keyboardTarget.current.focus();
        } else {
            console.log("Couldn't focus Soft Keyboard, Element Reference is missing.");
        }
    }

    if (!host) return <></>;
    return (
        <Page ref={mainPage} flexDirection="column" flexbox padding={0} background={"linear-gradient(#1f1f1f, #111)"}>
            <TopBar fullscreen={fullscreen} pinned={topBarPinned}>
                <TopBarSection flex>
                    {fullscreen && <Popup content='Pin Top Bar' inverted position='bottom right' trigger={<Button size="tiny" icon="pin" secondary={!topBarPinned} onClick={()=>{ setTopBarPinned(!topBarPinned); }} />} />}
                    <Label color={readStatus()}>{connectionState}</Label>
                </TopBarSection>
                <TopBarSection flex align="center">
                    <Label>{desktopName} ({host.displayName})</Label>
                </TopBarSection>
                <TopBarSection flex align="right">
                    {/* <Button size="tiny" color="red" onClick={()=>{ pushThumbnail(); }} icon="upload"/> */}
                    {connectionState.toLowerCase() === "disconnected" && <Button size="tiny" secondary onClick={()=>{ connectSession(); }}><Icon name="refresh"/> Reconnect</Button>}
                    <Popup content='Capture Screenshot' inverted position='bottom right' trigger={<Button disabled={!vncConnected} size="tiny" icon="camera" secondary onClick={()=>{ grabScreenshot(); }} />} />
                    <Popup content='Sync Clipboard' inverted position='bottom right' trigger={<Button disabled={!vncConnected} size="tiny" icon="clipboard" secondary onClick={()=>{ pushClipboard(); }} />} />
                    <Popup inverted on='click' position='bottom right' trigger={<Button disabled={!vncConnected} size="tiny" icon="keyboard" secondary  />} >
                        <Button fluid disabled={!vncConnected} size="tiny" content="Send CTRL+ALT+DEL" icon="keyboard" secondary onClick={()=>{ sendCtrlAltDel(); }} />
                        <Button fluid disabled size="tiny" content="Open Keyboard" icon="keyboard" secondary onClick={()=>{ triggerSoftKeyboard(); }} />
                    </Popup>
                    <Popup content='Toggle Fullscreen Mode' inverted position='bottom right' trigger={<Button size="tiny" icon="expand" secondary onClick={()=>{ goFullScreen(); }}/>} />
                </TopBarSection>
            </TopBar>
            <Screen className="screen" fullscreen={fullscreen} ref={vncScreen}>
                {!vncConnected && <Centered><h2>Disconnected</h2>Click "Reconnect" on the top bar to reconnect.</Centered>}
                <SoftKeyboardTarget  autoCapitalize="off" autoComplete="off" spellCheck="false" tabIndex={-1} ref={keyboardTarget} onKeyUp={(e)=>{
                    if (vncConnection) {
                        console.log(`Sending ${e.keyCode}`);
                        vncConnection.sendKey(e.charCode, "");
                    }
                }}/>
            </Screen>
        </Page>
    );
}