import React, { useContext, useEffect, useState } from 'react';

import Request from 'request-promise';
import Styled from 'styled-components';
import * as FileSaver from 'file-saver';
import { Button, ButtonProps, Popup, Image, Label } from 'semantic-ui-react';
import { AppContext } from '../App';
import Config from '../Config';

const Sidebar = Styled.div`
	width:66px;
	z-index:100;
`;

const SidebarContent = Styled.div<{ toggled: boolean }>`
	background:#111;
    width:${ p => p.toggled ? '360px' : '66px' };
    padding:8px;
    display:flex;
    flex-direction:column;
    transition:width linear .15s;
    overflow:hidden;
	
	position:absolute;
	height:100vh;
`;
const SidebarPush = Styled.div`
    flex:1;
`;
const SidebarItemContainer = Styled.div`
    margin-bottom:5px;
`;

const ProfileWidget = Styled.div<{ isAdmin?: boolean }>`
	height:46.28px;
	border-radius:.28571429rem;
	
	background-color:#1B1C1D;
	position:relative;

	overflow:hidden;
	display:flex;
	user-select:none;

	.imageContainer {
		img {
			width:50px;
			height:50px;

			background-image: url(https://smallimage.in/vydusmbl8h1qs211l5mfn3d6zoaoasne.png);
    		background-size: cover;
		}
		overflow:hidden;
		border-radius:.28571429rem;
		display:inline-block;
		min-width:50px;
		width:50px;
		height:100%;
		${ p => p.isAdmin && `border:solid 2px #DB2828;`}
	}

	.name {
		padding-left: 1.5em!important;
		padding-right: 1.5em!important;
		padding:.78571429em .78571429em .78571429em;
		display:inline-block;

		font-size: 1.28571429rem;

		font-weight: 700;
		line-height: 1em;
		font-style: normal;
		text-align: center;
		text-decoration: none;
		font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
		color:#fff;

		margin-left:-50px;
		flex:1;
	}
`;

export default () => {
    const ctx = useContext(AppContext);

    const logOut = () => {
        localStorage.removeItem("token");
        window.location.href = "/";
    }

    const toggleSidebar = () => {
        ctx.setSidebarVisible( !ctx.sidebarVisible );
	}
	
	const reloadConfiguration = async () => {
		try {
            const hs = await Request({
                method: "GET",
                uri: `${ctx.getAPIEndpoint()}/api/v1/reload`,
                headers: {
                    Authorization: `JWT ${ctx.authToken}`,
                },
            });
			window.location.reload();
        } catch (e) {
            ctx.setCurrentError(`Error reloading Hosts: ${e}`);
            console.log(e);
        }
	}

	const createSupportEmail = () => {
		const bundle = {
			url: window.location.href,
			jwt: ctx.authToken,
			endpoints: {
				ws: ctx.getWSEndpoint(),
				api: ctx.getAPIEndpoint(),
			},
			currentError: ctx.currentError,
		};

		const bundleText = `### Bridgeway Remote Access System ###
### Support Bundle ###

# JWT Token #
${bundle.jwt}

# URL #
${bundle.url}

# Endpoints #
WebSockets: ${bundle.endpoints.ws}
API: ${bundle.endpoints.api}

# Current Error (If Any) #
${bundle.currentError}`;
		const blob = new Blob([ bundleText ], { type: "text/plain;charset=utf-8" });
		FileSaver.saveAs(blob, "bridgeway-remote.bundle");
	}

    return (
        <Sidebar>
			<SidebarContent toggled={ctx.sidebarVisible}>
				<SidebarItemContainer>
					<Button icon="bars" onClick={()=>{ toggleSidebar(); }} secondary size="big"/>
				</SidebarItemContainer>
				{ctx.authState?.usingOAuth &&
				<SidebarItemContainer>
					<ProfileWidget isAdmin={ctx.authState.admin}>
						<div className="imageContainer">
							<Image src={ctx.authState.profile?.photo}/>
						</div>
						{ctx.sidebarVisible && <div className="name">{ctx.authState.profile?.displayName}</div>}
					</ProfileWidget>
				</SidebarItemContainer>}
				<SidebarItemContainer>
					<LabeledButton showlabel={ctx.sidebarVisible} icon="home" fluid content='Home' labelPosition='left' secondary size="big" onClick={()=>{ window.location.href="/"; }}/>
				</SidebarItemContainer>
				{ctx.authState?.admin && <SidebarItemContainer>
					<LabeledButton showlabel={ctx.sidebarVisible} icon="refresh" fluid content="Reload Config" labelPosition="left" secondary size="big" onClick={() => reloadConfiguration()} />	
				</SidebarItemContainer>}
				{ctx.sidebarItems.map( (item, ind) => {
					
					// Default to visible -- There must be a better way to do this.
					let visible = false;
					if (item.visible === true) visible = true;
					if (typeof item.visible === "undefined") visible = true;
					if (!visible) return; // Abort rendering..?
					
					return (
						<SidebarItemContainer key={ind}>
							<LabeledButton
								showlabel={ctx.sidebarVisible}
								disabled={item.disabled}
								icon={item.icon}
								size="big"
								content={item.label}
								fluid
								labelPosition='left'
								secondary
								onClick={()=>{ item.onClick(); }}
							/>
						</SidebarItemContainer>
					); 
				})}
				<SidebarItemContainer>
					<LabeledButton showlabel={ctx.sidebarVisible} icon="unlock" size="big" content='Logout' fluid labelPosition='left' secondary onClick={()=>{ logOut(); }}/>
				</SidebarItemContainer>

				<SidebarItemContainer>
					<LabeledButton showlabel={ctx.sidebarVisible} icon="life ring" size="big" content='Support Bundle' fluid labelPosition='left' secondary onClick={()=>{ createSupportEmail(); }}/>
				</SidebarItemContainer>

				{Config.beta &&
				<SidebarItemContainer>
					<LabeledButton showlabel={ctx.sidebarVisible} icon="bug" size="big" content='Beta Version' color="orange" fluid labelPosition='left' onClick={()=>{ ctx.setActiveModal({
						title: "Running in Beta",
						message: "You're running the Beta version of the Bridgeway Remote Access system.\nIts rough around the edges, please report any issues to IT!",
						buttons: [ {
							text: "Okay",
							color: 'green',
							icon: 'check',
						} ]
					}); }}/>
				</SidebarItemContainer>}

			</SidebarContent>
        </Sidebar>
    );
}

interface LabeledButtonProps extends ButtonProps {
    showlabel: boolean
}

function LabeledButton(props: LabeledButtonProps) {

    if (props.showlabel) {
        return (
            <Button {...props}>{props.children}</Button>
        );
    } else {
        return (
            <Button {...props} content={undefined} labelPosition={undefined}>{props.children}</Button>
        );
    }
}