import React from 'react'
import { Button, Header, Icon, Modal, SemanticICONS, SemanticCOLORS } from 'semantic-ui-react'

export interface ModalProps {
    icon?: SemanticICONS,
    title: string,
    message: string,
    buttons?: ModalButton[],

    onOpen?: Function,
    onClose?: Function,
}
export interface ModalButton {
    text: string,
    onClick?: Function,
    icon?: SemanticICONS,
    color?: SemanticCOLORS
    | 'facebook'
    | 'google plus'
    | 'vk'
    | 'twitter'
    | 'linkedin'
    | 'instagram'
    | 'youtube',
}

const ModalBasicExample = ( props: ModalProps ) => {

    const onClose = ()=>{
        if (props.onClose) props.onClose();
    }

    const fetchButton = (btn: ModalButton, index: number) => {
        const onClick = () => {
            if (btn.onClick) {
                btn.onClick();
            } else {
                onClose();
            }
        }
        return (
            <Button color={(btn.color || undefined)} key={index} onClick={onClick} inverted>
                {btn.icon && <Icon name={btn.icon} />} {btn.text}
            </Button>
        );
    };

    return (  
        <Modal basic size='small' open onClose={onClose}>
            <Header icon={(props.icon || null)} content={props.title} />
            <Modal.Content>
                <p>
                    {props.message}
                </p>
            </Modal.Content>
            <Modal.Actions>
                {props.buttons?.map((button, i) => {
                    return fetchButton(button, i);
                })}
            </Modal.Actions>
        </Modal>
    );
}

export default ModalBasicExample
