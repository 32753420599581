import React, { useState, createContext, useEffect } from 'react';

import Request from 'request-promise';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import Styled from 'styled-components';
import * as JWT from 'jsonwebtoken';

import AuthRoute from './components/AuthRoute';
import ModalBasicExample, { ModalProps } from './components/ModalMessage';

import HomeScreen from './screens/Home';
import LoginScreen from './screens/Login';
import ConnectScreen from './screens/Connect';
import LoadingScreen from './screens/Loading';
import OAuthScreen from './screens/OAuth';

import Sidebar from './components/Sidebar';

import { Message, Icon } from 'semantic-ui-react';

import SidebarItem from './interfaces/SidebarItem';
import OAuthToken from './interfaces/OAuthToken';

import Config from './Config';

export const AppContext = createContext({
	isReady: false,
	setReady: (state: boolean) => {},
	isAuthenticated: false,
	authToken: null as null | string,
	authState: null as null | OAuthToken,

	authenticate: async (password: string): Promise<boolean> => { return false; },

	loadingState: true,
	setLoadingState: (state: boolean) => {},

	sidebarVisible: false,
	setSidebarVisible: (state: boolean) => {},

	enableSidebar: false,
	setSidebarEnabled: (state: boolean) => {},
	
	activeModal: null as null | ModalProps,
	setActiveModal: ( modal: null | ModalProps ) => {},

	currentError: null as null | string,
	setCurrentError: (error: string) => {},

	pageTitle: "",
	setPageTitle: (title: string) => {},

	sidebarItems: [] as SidebarItem[],
	setSidebarItems: ( items: SidebarItem[] ) => {},

	consumeToken: async  (token: string): Promise<boolean> => { return true; },

	getAPIEndpoint: (): string => { return ""; },
	getWSEndpoint: (): string => { return ""; },
});

const PageContainer = Styled.div`
	display:flex;
	width:100vw;
	height:100vh;
`;

const ErrorContainer = Styled.div`
	position:fixed;
	left:50%;
	transform:translate(-50%);

	bottom:30px;
	width:80vw;
`;

function App() {
	const [ authState, setAuthState ] = useState(false);
	const [ readyState, setReadyState ] = useState(false);
	const [ authToken, setAuthToken ] = useState(null as null | string);
	const [ oauthToken, setOAuthToken ] = useState(null as null | OAuthToken);

	const [ loadingState, setLoadingState ] = useState(true);

	// Sidebar
	const [ sidebarVisible, setSidebarVisible ] = useState(false);
	const [ enableSidebar, setSidebarEnabled ] = useState(true);
	const [ sidebarItems, setSidebarItems ] = useState([] as SidebarItem[]);

	// Modal
	const [ activeModal, setActiveModal ] = useState(null as null | ModalProps);

	// Error Handling
	const [ currentError, setCurrentError ] = useState( null as null | string);

	// App Page Title
	const [ pageTitle, setPageTitle ] = useState("");

	useEffect(()=>{
		// Render Event
		let token = localStorage.getItem("token");
		if (token) {
			// We have a token, is it ready?	
			consumeToken(token);
		} else {
			// No token
			setAuthState(false);
			setReadyState(true);
		}
	}, []);

	useEffect(()=>{
		if (pageTitle === "") {
			document.title = "Bridgeway Remote Access";
		} else {
			document.title = `${pageTitle} - Bridgeway Remote Access`;
		}
	}, [ pageTitle ]);

	const notFound = ()=>{
		return <h1>404</h1>;
	}

	const getAPIEndpoint = () => {
		if (Config.development.enabled) {
			return Config.development.apiEndpoint;
		}
		return Config.apiEndpoint;
	}
	const getWSEndpoint = () => {
		if (Config.development.enabled) {
			return Config.development.webSocketEndpoint;
		}
		return Config.webSocketEndpoint;
	}

	const authenticate = async ( password:string ) => {
		// Attempts to authenticate against the API.
		try {
			const res = await Request({
				method: "POST",
				uri: `${getAPIEndpoint()}/api/v1/auth`,
				form: {
					password
				},
				json: true,
			});
			const tokenResult = await consumeToken(res.token);
			return tokenResult;
		} catch (e) {
			console.log("Failed to Authenticate", e);
			setCurrentError("Failed to Authenticated");
			return false;
		}
	
	}

	const consumeToken = async (token: string): Promise<boolean> => {
		const decoded = await JWT.decode(token);
		console.log(decoded);

		// Verify the token.
		await Request({
			method: 'GET',
			uri: `${getAPIEndpoint()}/api/v1/ping`,
			headers: {
				Authorization: `JWT ${token}`
			},
			json: true,
		})
		.then( r => {
			const result = r as { result: boolean };
			setAuthState(result.result);
			if (r.result) {
				setAuthToken(localStorage.getItem("token"));
				setOAuthToken(decoded as OAuthToken);
				setReadyState(true);
			}
		})
		.catch( err => {

		});

		// Set it if not already.
		localStorage.setItem("token", token);

		return true;
	}

	if (readyState) {
		return (
			<AppContext.Provider value={{
				isReady: readyState,
				setReady: setReadyState,
				
				authToken: authToken,
				authState: oauthToken,

				isAuthenticated: authState,
				authenticate,

				loadingState,
				setLoadingState,

				sidebarVisible,
				setSidebarVisible,
			
				enableSidebar,
				setSidebarEnabled,

				activeModal,
				setActiveModal,

				currentError,
				setCurrentError,

				pageTitle,
				setPageTitle,

				sidebarItems,
				setSidebarItems,

				consumeToken,

				getAPIEndpoint,
				getWSEndpoint,
			}}>
				{activeModal && <ModalBasicExample {...activeModal} onClose={()=>{
					console.log("Modal Closed");
					if (activeModal.onClose) { activeModal.onClose(); }
					setActiveModal(null);
					}}/>}
				<PageContainer>	
					{enableSidebar && <Sidebar/>}
					<BrowserRouter>
						<Switch>
							<AuthRoute path="/" exact component={HomeScreen} />
							<Route path="/login" exact component={LoginScreen} />
							<AuthRoute path="/connect/:host" exact component={ConnectScreen} />

							<Route path="/oauth/authorize" exact component={OAuthScreen}/>

							<Route render={notFound} />
						</Switch>
					</BrowserRouter>
				</PageContainer>
				{loadingState && <LoadingScreen/>}
				{currentError &&
				<ErrorContainer>
					<Message icon negative onDismiss={()=>{ setCurrentError(null); }}>
						<Icon name='warning'/>
						<Message.Content>
							<Message.Header>System Error</Message.Header>
							{currentError}
						</Message.Content>
					</Message>
				</ErrorContainer>}
			</AppContext.Provider>
		);
	}

	return <LoadingScreen/>;
}

export default App;
