import Styled from 'styled-components';
import styled from 'styled-components';

export default Styled.div<{ vertically?: boolean, horizontally?: boolean }>`
    display:block;

    left:50%;
    top:50%;
    transform:translate(-50%, -50%);

    position:absolute;

    text-align:center;
`;