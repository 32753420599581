import React from 'react';

function useInterval(callback: () => void, delay: number) {
  const savedCallback = React.useRef<() => void>();

  React.useEffect(() => {
    savedCallback.current = callback;
  });

  React.useEffect(() => {
    const tick = () => {
        if (savedCallback.current) {
              savedCallback.current();
        }
    }

    const id = setInterval(tick, delay);

    return () => { clearInterval(id); };
  }, [delay]);
}
export default useInterval;